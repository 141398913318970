/*
* Side Menu
*/

import Menu from './components/Menu.vue';
import { createApp, h } from 'vue';
const app = createApp({
    render: () => h(Menu)  // Render the Menu component
});


let newItem = $('<div class="testmenu"></div>');
$('#side-menu-component').append(newItem);

app.mount('#side-menu-component');

String.prototype.beginsWith = function (string) {
    return (this.indexOf(string) === 0);
};
